export interface ApiConfig {
  url: string;
  timeout: number;
}

let ENV = "DEV";

export const DEFAULT_API_CONFIG: ApiConfig = {
  url:
    ENV === "DEV"
      ? "https://staging-argold-catalog.8848digitalerp.com/"
      : "https://argold-catalog.8848digital.com/",
  timeout: 100000,
};
